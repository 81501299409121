<template>
	<div class="report_all report_content">
		<div class="filter">
			<div class="search">
				<template v-if="show_list">
					<el-date-picker
						size="small"
						v-model="list_year"
						type="year"
						style="width:118px;"
						:clearable="false"
						value-format="yyyy"
						@change="getAllList(), getList()"
					/>

					<div class="area">
						<span>填报单位：</span>
						<el-select
							size="small"
							v-model="list_area"
							popper-class="global_select_popper"
							style="width:118px;"
							:disabled="qu_project_power.area > 0"
							@change="getAllList(), getList()"
						>
							<el-option
								v-for="item in areaList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</div>
				</template>

				<template v-else>
					<div class="back" @click="(show_list = true), getAllList()">
						<i class="el-icon-arrow-left"></i>
						返回
					</div>
					<el-date-picker
						size="small"
						v-model="month"
						type="month"
						placeholder="选择月度"
						value-format="yyyy-MM"
						:clearable="false"
						style="width:118px;"
						@change="getList"
					></el-date-picker>
					<div
						class="area"
						v-if="!qu_project_power.area || qu_project_power.area === -1"
					>
						<span>填报单位：</span>
						<el-select
							size="small"
							v-model="area"
							popper-class="global_select_popper"
							@change="
								initMonthForm();
								getList();
							"
							style="width:118px;"
						>
							<el-option
								v-for="item in areaList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</div>
				</template>
			</div>
			<el-button
				size="small"
				type="primary"
				@click="exportForm"
				class="button_98px"
				v-if="!show_list"
			>
				导出表格
			</el-button>
		</div>

		<div class="content">
			<div class="report_table" v-if="show_list">
				<span class="label">
					{{ list_year }}年{{
						areaList.find((it) => it.value == list_area).label
					}}项目进度汇总表
				</span>
				<div class="table">
					<el-table :data="list_data" height="100%" style="width: 100%" border>
						<el-table-column label="月份" min-width="68" align="center">
							<template slot-scope="scope">
								{{ scope.row.month }}月
							</template>
						</el-table-column>
						<el-table-column label="地区" min-width="68" align="center">
							<template slot-scope="scope">
								<div>{{ scope.row.xiang }}</div>
							</template>
						</el-table-column>
						<el-table-column label="是否填报" min-width="68" align="center">
							<template slot-scope="scope">
								<span :style="{ color: scope.row.id ? '#06BA3D' : 'red' }">{{
									scope.row.id ? "是" : "否"
								}}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" min-width="68" align="center">
							<template slot-scope="scope">
								<span class="button" @click="editForm(scope.row)">去填报</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>

			<template v-else>
				<h3>
					{{ initArea(area) }}对口帮扶资金项目计划单体项目进度汇总表（按县统计汇总）
				</h3>
				<div class="table_info">
					<div>填报单位：{{ initArea(area) }}</div>
					<div>
						填报时间：{{ new Date(month).getFullYear() }}年{{
							new Date(month).getMonth() + 1
						}}月
					</div>
				</div>
				<div class="report_table">
					<div class="table">
						<el-table :data="tableData" style="width: 100%" height="100%">
							<el-table-column
								type="index"
								label="序号"
								width="54"
								align="center"
							></el-table-column>
							<el-table-column label="州市" min-width="76" align="center">
								<template slot-scope="scope">
									<div v-if="!scope.row.edit">{{ scope.row.shi }}</div>
									<el-input
										v-else
										size="mini"
										v-model="monthForm.shi"
										placeholder="州名"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column label="县" min-width="68" align="center">
								<template slot-scope="scope">
									<div v-if="!scope.row.edit">{{ scope.row.xiang }}</div>
									<el-input
										v-else
										size="mini"
										v-model="monthForm.xiang"
										placeholder="县名"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column label="基本信息" align="center">
								<el-table-column label="项目类别" min-width="92" align="center">
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">{{ scope.row.project_genre }}</div>
										<el-input
											v-else
											size="mini"
											v-model="monthForm.project_genre"
											placeholder="填写类别"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="项目名称" min-width="120" align="center">
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">{{ scope.row.project_name }}</div>
										<el-input
											v-else
											size="mini"
											v-model="monthForm.project_name"
											placeholder="填写名称"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="项目内容" min-width="138" align="center">
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">{{ scope.row.project_value }}</div>
										<el-input
											v-else
											size="mini"
											v-model="monthForm.project_value"
											placeholder="填写内容"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column
									:label="'项目所在地\n（乡镇、村）'"
									min-width="120"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">{{ scope.row.address }}</div>
										<el-input
											v-else
											size="mini"
											v-model="monthForm.address"
											placeholder="填写地址"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column
									:label="'项目联系人\n及电话'"
									min-width="120"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">{{ scope.row.people_phone }}</div>
										<el-input
											v-else
											size="mini"
											v-model="monthForm.people_phone"
											placeholder="姓名，电话"
										></el-input>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="项目实施情况" align="center">
								<el-table-column label="前期" align="center">
									<el-table-column label="批复/下达" min-width="90" align="center">
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkBox(monthForm, 'before_1')"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.before_1 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.before_1 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column
										:label="'招投标/\n政府采购'"
										min-width="92"
										align="center"
									>
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkBox(monthForm, 'before_2')"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.before_2 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.before_2 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="开工" min-width="60" align="center">
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkBox(monthForm, 'before_3')"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.before_3 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.before_3 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table-column>
								<el-table-column label="进度" align="center">
									<el-table-column label="20%" min-width="54" align="center">
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkRadio(monthForm, 1)"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.going == 1 }">
													<img class="default" src="../../../assets/img/radio.png" alt="" />
													<img
														class="active"
														src="../../../assets/img/radio_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.going == 1 }">
													<img class="default" src="../../../assets/img/radio.png" alt="" />
													<img
														class="active"
														src="../../../assets/img/radio_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="50%" min-width="54" align="center">
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkRadio(monthForm, 2)"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.going == 2 }">
													<img class="default" src="../../../assets/img/radio.png" alt="" />
													<img
														class="active"
														src="../../../assets/img/radio_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.going == 2 }">
													<img class="default" src="../../../assets/img/radio.png" alt="" />
													<img
														class="active"
														src="../../../assets/img/radio_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="80%" min-width="54" align="center">
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkRadio(monthForm, 3)"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.going == 3 }">
													<img class="default" src="../../../assets/img/radio.png" alt="" />
													<img
														class="active"
														src="../../../assets/img/radio_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.going == 3 }">
													<img class="default" src="../../../assets/img/radio.png" alt="" />
													<img
														class="active"
														src="../../../assets/img/radio_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="100%" min-width="60" align="center">
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkRadio(monthForm, 4)"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.going == 4 }">
													<img class="default" src="../../../assets/img/radio.png" alt="" />
													<img
														class="active"
														src="../../../assets/img/radio_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.going == 4 }">
													<img class="default" src="../../../assets/img/radio.png" alt="" />
													<img
														class="active"
														src="../../../assets/img/radio_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table-column>
								<el-table-column label="后期" align="center">
									<el-table-column label="完工" min-width="50" align="center">
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkBox(monthForm, 'after_1')"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.after_1 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.after_1 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="验收" min-width="50" align="center">
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkBox(monthForm, 'after_2')"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.after_2 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.after_2 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="审计" min-width="50" align="center">
										<template slot-scope="scope">
											<div
												class="icon_box"
												@click="checkBox(monthForm, 'after_3')"
												v-if="scope.row.edit"
											>
												<div :class="{ icon: true, active: monthForm.after_3 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
											<div class="icon_box" v-else>
												<div :class="{ icon: true, active: scope.row.after_3 == 1 }">
													<img
														class="default"
														src="../../../assets/img/checkbox.png"
														alt=""
													/>
													<img
														class="active"
														src="../../../assets/img/checkbox_active.png"
														alt=""
													/>
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table-column>
							</el-table-column>
							<el-table-column label="资金拨付使用情况" align="center">
								<el-table-column
									:label="'计划帮扶资金\n（万元）'"
									min-width="108"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.money1 !== '' &&
														scope.row.money1 !== null &&
														scope.row.money1 !== undefined
												"
												>{{ scope.row.money1 }}万元</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.money1"
											:step="1"
											:controls="false"
											placeholder="填写金额"
										></el-input-number>
									</template>
								</el-table-column>
								<el-table-column
									:label="'累计报账资金\n（万元）'"
									min-width="108"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.money2 !== '' &&
														scope.row.money2 !== null &&
														scope.row.money2 !== undefined
												"
												>{{ scope.row.money2 }}万元</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.money2"
											:step="1"
											:controls="false"
											placeholder="填写金额"
										></el-input-number>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="备注" min-width="145" align="center">
								<template slot-scope="scope">
									<div v-if="!scope.row.edit">{{ scope.row.remark }}</div>
									<el-input
										v-else
										size="mini"
										v-model="monthForm.remark"
										placeholder="填写备注"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column
								label="操作"
								align="center"
								width="160"
								v-if="qu_project_power.power.edit"
							>
								<template slot-scope="scope">
									<div v-if="scope.row.id">
										<el-button
											size="mini"
											type="primary"
											class="button_58px"
											v-if="!monthForm.id"
											:disabled="loading"
											@click="editRow(scope.row)"
											>编辑
										</el-button>
										<el-button
											size="mini"
											type="danger"
											class="button_58px"
											v-if="!monthForm.id"
											:disabled="loading"
											@click="del(scope.row, scope.$index)"
											>删除
										</el-button>
										<el-button
											size="mini"
											type="primary"
											class="button_58px"
											:disabled="loading"
											v-if="monthForm.id && monthForm.id == scope.row.id"
											@click="add"
											>保存
										</el-button>
										<el-button
											size="mini"
											class="button_58px"
											:disabled="loading"
											v-if="monthForm.id && monthForm.id == scope.row.id"
											@click="editCancel"
											>取消
										</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="add" v-if="qu_project_power.power.edit">
						<el-button
							type="text"
							icon="el-icon-plus"
							@click="add"
							:disabled="loading"
							>保存并添加新项目
						</el-button>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import api from "@/api/methods/index";
import computedData from "@/mixins/computedData.js";
import util from "@/utils/public.js";
import container from "@/utils/container";

export default {
	mixins: [computedData],
	data() {
		return {
			month: "",
			area: "",
			tableData: [],
			monthForm: {
				shi: "",
				xiang: "",
				project_genre: "",
				project_name: "",
				project_value: "",
				address: "",
				people_phone: "",
				before_1: 0,
				before_2: 0,
				before_3: 0,
				after_1: 0,
				after_2: 0,
				after_3: 0,
				money1: undefined,
				money2: undefined,
				remark: "",
				going: "",
				edit: true, //going为供单选的值,
			},
			areaList: [
				{ value: 1, label: "州本级" },
				{ value: 2, label: "玛沁县" },
				{ value: 5, label: "甘德县" },
				{ value: 6, label: "达日县" },
				{ value: 4, label: "班玛县" },
				{ value: 3, label: "久治县" },
				{ value: 7, label: "玛多县" },
			],
			loading: false,

			// 列表
			show_list: true,
			list_year: "",
			list_area: 1,
			list_data: [],
		};
	},
	computed: {
		qu_project_power() {
			return this.$store.state.user.current_user.qu_project_power;
		},
	},
	created() {
		if (this.qu_project_power.area > 0) {
			this.area = this.qu_project_power.area;
			this.list_area = this.qu_project_power.area;
		} else {
			this.area = 1;
			this.list_area = 1
		}
		let year = new Date().getFullYear();
		let month = new Date().getMonth() + 1;
		if (month < 10) month = "0" + month;
		this.month = year + "-" + month;
		this.initMonthForm();
		this.getList();

		this.list_year = new Date().getFullYear() + "";
		this.getAllList();
	},
	methods: {
		initMonthForm() {
			this.monthForm = {
				shi: "",
				xiang: "",
				project_genre: "",
				project_name: "",
				project_value: "",
				address: "",
				people_phone: "",
				before_1: 0,
				before_2: 0,
				before_3: 0,
				after_1: 0,
				after_2: 0,
				after_3: 0,
				money1: undefined,
				money2: undefined,
				remark: "",
				going: "",
				edit: true, //going为供单选的值,
			};
			this.monthForm.shi = "果洛州";
			this.monthForm.xiang = this.initArea(this.area);
		},
		add() {
			if (!this.monthForm.shi) {
				this.$message.error("请填写州名");
				return;
			}
			if (!this.monthForm.xiang) {
				this.$message.error("请填写县名");
				return;
			}
			if (!this.monthForm.project_name) {
				this.$message.error("请填写项目名称");
				return;
			}
			let param = {
				add_time: this.month,
				area: this.area,
				shi: this.monthForm.shi,
				xiang: this.monthForm.xiang,
				project_name: this.monthForm.project_name,
				before_1: this.monthForm.before_1,
				before_2: this.monthForm.before_2,
				before_3: this.monthForm.before_3,
				after_1: this.monthForm.after_1,
				after_2: this.monthForm.after_2,
				after_3: this.monthForm.after_3,
			};
			if (this.monthForm.project_genre) {
				param.project_genre = this.monthForm.project_genre;
			}
			if (this.monthForm.project_value) {
				param.project_value = this.monthForm.project_value;
			}
			if (this.monthForm.address) {
				param.address = this.monthForm.address;
			}
			if (this.monthForm.address) {
				param.address = this.monthForm.address;
			}
			if (this.monthForm.people_phone) {
				param.people_phone = this.monthForm.people_phone;
			}
			if (this.monthForm.going) {
				param[`going_${this.monthForm.going}`] = 1;
			}
			if (this.monthForm.money1) {
				param.money1 = Number(this.monthForm.money1) * 10000;
			}
			if (this.monthForm.money2) {
				param.money2 = Number(this.monthForm.money2) * 10000;
			}
			if (this.monthForm.remark) {
				param.remark = this.monthForm.remark;
			}
			if (this.monthForm.id) {
				param.id = this.monthForm.id;
			}
			api.report.poolAdd(param).then((res) => {
				if (res.data.state == 1) {
					this.$message({
						message: `记录${param.id ? "修改" : "添加"}成功`,
						type: "success",
					});
					this.initMonthForm();
					this.getList(false);
				}
			});
		},
		async getList(load = true) {
			this.loading = true;
			if (load != false) {
				this.tableData = [];
			}
			let param = {
				add_time: this.month,
				area: this.area,
			};
			const res = await api.report.poolLists(param);
			if (res.data) {
				this.tableData = res.data.lists || [];
				if (this.tableData.length > 0) {
					this.tableData.forEach((item) => {
						item.edit = false;
						this.initRow(item);
					});
					this.tableData = [...this.tableData, this.monthForm];
					console.log("this", this.tableData);
				} else {
					this.tableData = [this.monthForm];
				}
			}
			this.loading = false;
		},
		initRow(item) {
			if (
				item.money1 === "" ||
				item.money1 === null ||
				item.money1 === undefined
			) {
				item.money1 = undefined;
			} else {
				item.money1 = Number(item.money1) / 10000;
			}
			if (
				item.money2 === "" ||
				item.money2 === null ||
				item.money2 === undefined
			) {
				item.money2 = undefined;
			} else {
				item.money2 = Number(item.money2) / 10000;
			}
			if (item.going_1 == 1) item.going = 1;
			if (item.going_2 == 1) item.going = 2;
			if (item.going_3 == 1) item.going = 3;
			if (item.going_4 == 1) item.going = 4;
		},
		newForm() {
			this.tableData.push(this.monthForm);
		},
		exportForm() {
			let param = {
				add_time: this.month,
				area: this.area,
			};
			let _url = window.location.href;
			let __url = _url.split("#")[0];
			let url =
				__url +
				"index.php/cqh/Pool/leadingOut/?token=" +
				this.$store.state.user.token +
				"&add_time=" +
				param.add_time +
				"&area=" +
				param.area;
			api.report.poolOut(param).then((res) => {
                if (container.dd) {
                    dd.biz.util.openLink({
                        url, //要打开链接的地址
                        onSuccess: function(result) {
                            console.log("打开导出地址成功");
                        },
                        onFail: function(err) {
                            console.log("打开导出地址失败", err);
                        },
                    });
                } else if (container.browser) {
                    window.open(url);
                }
			});
		},
		checkBox(obj, data) {
			if (obj.id && !obj.edit) return;
			if (this.monthForm[data] == 1) {
				this.monthForm[data] = 0;
			} else {
				this.monthForm[data] = 1;
			}
		},
		checkRadio(obj, data) {
			if (obj.id && !obj.edit) return;
			if (this.monthForm.going) {
				this.$set(
					this.monthForm,
					"going",
					data == this.monthForm.going ? "" : data
				);
			} else {
				this.$set(this.monthForm, "going", data);
			}
		},
		del(row, index) {
			const h = this.$createElement;
			this.$msgbox({
				title: "提示",
				message: h("p", null, "确定要删除该条报表记录吗?"),
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				beforeClose: (action, instance, done) => {
					if (action === "confirm") {
						api.report.poolDelete({ id: row.id }).then((res) => {
							if (res.data.state == 1) {
								this.$message({
									message: "删除成功",
									type: "success",
								});
								this.tableData.splice(index, 1);
							}
						});
						done();
					} else {
						done();
					}
				},
			}).catch(() => {});
		},
		editRow(row) {
			this.initMonthForm();
			this.tableData = this.tableData.filter((a) => a.id);
			row.edit = true;
			this.monthForm = JSON.parse(JSON.stringify(row));
		},
		//取消编辑
		editCancel() {
			this.initMonthForm();
			this.tableData.forEach((item) => {
				item.edit = false;
			});
			this.tableData = [...this.tableData, this.monthForm];
		},

		getAllList() {
			api.report
				.poolAllList({
					year: this.list_year,
					area: this.list_area || 0,
				})
				.then((res) => {
					if (res.data.state == 1) {
						const data = (res.data.lists || []).map((it) => ({
							...it,
							month: util.dateFormat(it.add_time, "M"),
						}));
						let result = [];
						for (let i = 0; i < 12; i++) {
							const item = data.find((it) => it.month == i + 1);
							if (item) {
								result.push(item);
							} else {
								result.push({
									month: i + 1,
									xiang: this.areaList.find((it) => it.value == this.list_area).label,
								});
							}
						}
						this.list_data = result;
						console.log("this.list_data", this.list_data);
					}
				});
		},
		async editForm(row) {
			const date = util.dateFormat(`${this.list_year}-${row.month}`, "YYYY-MM");
			const area = this.list_area;
			this.month = date;
			this.area = area;
			this.initMonthForm();
			await this.getList();
			this.show_list = false;
		},
	},
};
</script>

<style scoped lang="less">
.report_all {
	.icon_box {
		display: flex;
		justify-content: center;
		align-items: center;
		user-select: none;

		.icon {
			width: 16px;
			height: 16px;
			position: relative;
			user-select: none;

			img {
				position: absolute;
				width: 16px;
				height: 16px;
				left: 0;
				right: 0;
				top: 0;
				transition: opacity 0.3s;

				&.default {
					opacity: 1;
				}

				&.active {
					opacity: 0;
				}
			}

			&.active {
				img {
					&.default {
						opacity: 0;
					}

					&.active {
						opacity: 1;
					}
				}
			}
		}
	}
}

.report_table {
	.label {
		line-height: 60px;
		text-align: center;
		font-size: 24px;
		width: 100%;
		display: inline-block;
		flex-shrink: 0;
	}

	.button {
		color: #409eff;
		cursor: pointer;
		font-weight: 500;
	}
}

.back {
	display: flex;
	align-items: center;
	color: #409eff;
	margin-right: 10px;
	cursor: pointer;
}
</style>
